import Choices from 'choices.js';
import $ from '../core/Dom';

export default el => {

    let choices = null;

    const init = () => {
        choices = new Choices(el, {
            removeItemButton: true,
            labelId: el.getAttribute('aria-labelledby'),
            position: 'below',
            resetScrollPosition: false
        });
    };

    const destroy = () => {
        if (choices) {
            choices.destroy();
            choices = null;
        }
    };

    return {
        init,
        destroy
    };
};
