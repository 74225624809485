import $ from '../core/Dom';

export default el => {
    const html = $('html');
    let observer = null;

    const onIntersectionChange = entries => {
        if (entries.filter(entry => entry.isIntersecting).length) {
            html.addClass('js-at-bottom');
        } else {
            html.removeClass('js-at-bottom');
        }
    };

    const init = () => {
        observer = new IntersectionObserver(onIntersectionChange);
        observer.observe(el);
    };

    const destroy = () => {
        if (observer) {
            observer.unobserve(el);
            observer = null;
        }
    };

    return {
        init,
        destroy
    };
};
