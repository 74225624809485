import $ from '../core/Dom';

export default el => {
    const $billingRange = $(el).find('#hourly-rate');
    const $billingText = $(el).find('[data-hourly-rate]');
    const $hoursRange = $(el).find('#hours-range');
    const $hoursText = $(el).find('[data-hours-range]');

    const config = {
        side_costs_deduction: 0.3808,
        employee_share: 0.7,
        min_salary: 3000
    };

    const percentage = val => `${parseInt(val, 10)} %`;

    const money = val => `${parseInt(val, 10)} &euro;`;

    const dom = (selector, value, isPercentage) => {
        if (isPercentage) {
            $(selector).html(percentage(value));
        } else {
            $(selector).html(money(value));
        }
    };

    const update = () => {
        const billingRate = $billingRange.val();
        const hours = $hoursRange.val();
        const revenue = billingRate * hours;
        const employeeShare = revenue * config.employee_share;
        const calculatedEmployeeSalary = employeeShare / (1 + config.side_costs_deduction);
        const employeeSalary = calculatedEmployeeSalary > config.min_salary ? calculatedEmployeeSalary : config.min_salary;
        const sideCosts = employeeShare - employeeSalary;

        $billingText.text(billingRate);
        $hoursText.text(hours);

        dom('[data-revenue]', revenue);
        dom('[data-share]', employeeShare);
        dom('[data-side-costs]', sideCosts);
        dom('[data-salary]', employeeSalary);
    };

    const init = () => {
        if (!$billingRange.length || !$hoursRange.length) {
            return;
        }

        $billingRange.on('input change', update);
        $hoursRange.on('input change', update);
        update();
    };

    const destroy = () => {
        $billingRange.off('input change', update);
        $hoursRange.off('input change', update);
    };

    return {
        init,
        destroy
    };
};
