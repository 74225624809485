import gsap from 'gsap';
import serialize from 'form-serialize';
import agent from '../core/request';
import $ from '../core/Dom';

export default (el, props) => {
    const dom = $(el);
    const form = el;
    const addLinkButton = dom.find('[data-add-link]');
    const submitButton = dom.find('[data-submit]');

    const dialog = $(el.parentElement).find('[data-dialog]');
    const dialogEmoji = dialog.find('[data-dialog-emoji]');
    const dialogHeading = dialog.find('[data-dialog-heading]');
    const dialogText = dialog.find('[data-dialog-text]');
    const dialogClose = dialog.find('[data-dialog-close]');

    let index = 1;
    let lastFocus = null;

    const addLink = () => {
        index += 1;
        const field = $(addLinkButton.previous().get(0).cloneNode(true));
        field.find('input').attr('id', `links${index}`).val('');
        field.find('label').attr('for', `links${index}`);
        addLinkButton.parent().get(0).insertBefore(field.get(0), addLinkButton.get(0));
    };

    const dialogKeyUp = e => {
        const key = e.key || e.keyCode || e.which || null;
        if (['Escape', 27].indexOf(key) > -1) {
            closeDialog();
        }
    };

    const closeDialog = () => {
        dialog.attr('hidden', '');
        dialogClose.off('click', closeDialog);
        dialogClose.off('focusout');
        $('body').off('keyup', dialogKeyUp);
        lastFocus.focus();
    };

    const openDialog = response => {
        lastFocus = document.activeElement;
        dialogEmoji.text(response.emoji);
        dialogHeading.text(response.heading);
        dialogText.html(response.text);
        dialog.attr('hidden', null);
        dialogClose.on('click', closeDialog);
        dialogHeading.focus();
        dialogClose.on('focusout', () => {
            dialogClose.focus();
        });
        $('body').on('keyup', dialogKeyUp);
    };

    const submitForm = () => {
        const params = serialize(el);
        submitButton.attr('disabled', '');
        agent
            .post('/')
            .accept('application/json')
            .send(params)
            .end((error, res) => {
                console.log([error, res]);
                const response = JSON.parse(res.text);
                if (error || !response.success) {
                    console.log('error', response);
                    openDialog(props.error);
                } else {
                    openDialog(props.success);
                    form.reset();
                    submitButton.attr('disabled', null);
                }
            });
    };

    const onSubmit = e => {
        e.preventDefault();
        if (!form.checkValidity()) {
            const firstInvalidField = dom.find('[aria-invalid="true"]').first();
            const offset = firstInvalidField.offset().top - 20;
            gsap.to(window, { duration: 1, scrollTo: { y: offset, autoKill: false }, ease: 'power2.inOut', onComplete: () => { firstInvalidField.get(0).focus(); } });
            return;
        }
        submitForm();
    };

    const removeError = e => {
        e.target.removeAttribute('aria-invalid');
        e.target.removeEventListener('input', removeError);
    };

    const init = () => {
        addLinkButton.on('click', addLink);
        submitButton.on('click', onSubmit);
        Array.from(form.elements).forEach(field => {
            field.addEventListener('invalid', () => {
                const errorDialog = document.querySelector(`#${field.getAttribute('aria-describedby')} span`);
                if (errorDialog && field.validationMessage) {
                    errorDialog.innerHTML = field.validationMessage;
                }
                field.setAttribute('aria-invalid', 'true');
                field.addEventListener('input', removeError);
            });
        });
    };

    const destroy = () => {
        addLinkButton.off('click', addLink);
        submitButton.off('click', onSubmit);
    };

    return {
        init,
        destroy
    };
};
