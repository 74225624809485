import serialize from 'form-serialize';
import request from '../core/request';
import $ from '../core/Dom';

export default el => {
    const dom = $(el);
    const form = dom.find('[data-form]').get(0);
    const clearButton = dom.find('[data-clear]');
    const checkboxes = dom.find('input[type="checkbox"]');
    const list = dom.find('[data-list]');
    const pagination = dom.find('[data-pagination]');

    const displayResponse = res => {
        const response = $(res);
        const items = response.find('[data-list-item]');
        const paging = response.find('[data-pagination]:not([hidden])');
        list.empty().append(items);
        if (paging.length) {
            pagination.attr('hidden', null);
            pagination.empty().append(paging.get(0).children);
        } else {
            pagination.attr('hidden', '');
        }
    };

    const update = () => {
        const filters = serialize(form, { hash: true });
        const query = Object.keys(filters).map(filter => `${filter}=${filters[filter].join(',')}`);
        let url = dom.data('url');
        if (query.length) {
            clearButton.attr('hidden', null);
            url = `${dom.data('url')}?${query.join('&')}`;
        } else {
            clearButton.attr('hidden', '');
        }

        console.log(url);

        request
            .get(url, { cache: true, ajax: true })
            .then(res => {
                if (res && res.status === 200) {
                    window.history.pushState({ filters, response: res.text }, document.title, url);
                    displayResponse(res.text);
                } else {
                    console.log(res);
                }
            }, error => {
                console.error('Error', error);
            });
    };

    const clear = e => {
        if (e) {
            e.preventDefault();
        }
        checkboxes.each(node => {
            node.checked = false;
        });
        update();
    };

    const onSubmit = e => {
        e.preventDefault();
        update();
    };

    const onPopState = e => {
        if (e.state) {
            console.log(e.state);
            const filterKeys = Object.keys(e.state.filters);
            if (filterKeys.length) {
                Object.entries(e.state.filters)
                    .forEach(([key, value]) => {
                        checkboxes.filter(`[name^="${key}"]`)
                            .each(node => {
                                node.checked = value.includes(node.value);
                            });
                    });
            } else {
                checkboxes.each(node => {
                    node.checked = false;
                });
            }
            displayResponse(e.state.response);
        } else {
            console.log('clear');
            clear();
        }
    };

    const init = () => {
        console.info(checkboxes.length);
        dom.on('submit', onSubmit);
        clearButton.on('click', clear);
        checkboxes.on('change', update);
        window.addEventListener('popstate', onPopState);
    };

    const destroy = () => {
        dom.off('submit', onSubmit);
        clearButton.on('click', clear);
        checkboxes.off('change', update);
        window.removeEventListener('popstate', onPopState);
    };

    return {
        init,
        destroy
    };
};
