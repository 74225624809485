import $ from '../core/Dom';

export default el => {
    const dom = $(el);
    const field = dom.find('[type="file"]');
    const output = dom.find('[data-file-output]');
    const button = dom.find('[data-attachment]');
    const clearButton = dom.find('[data-clear]');

    const change = () => {
        const files = [...field.get(0).files];
        if (files.length) {
            output.html(files.map(file => file.name).join('<br>'));
            clearButton.attr('hidden', null);
        } else {
            output.html('No files selected');
            clearButton.attr('hidden', '');
        }
    };

    const clear = () => {
        field.get(0).value = null;
        change();
    };

    const select = () => {
        field.get(0).click();
    };

    const init = () => {
        button.on('click', select);
        field.on('change', change);
        clearButton.on('click', clear);
    };

    const destroy = () => {
        button.off('click', select);
        field.off('change', change);
        clearButton.off('click', clear);
    };

    return {
        init,
        destroy
    };
};
