import { veroprosentti } from 'veroprosentti';
import $ from '../core/Dom';

export default el => {
    const $billingRange = $(el).find('#revenue-range');
    const $salaryRange = $(el).find('#salary-range');

    const config = {
        tax: 0.20,
        vat: 0.24,
        retirement_insurance: 1435,
        book_keeping: 950,
        insurances: 360,
        employment_expenses: 0.30,
    };

    const percentage = val => `${parseInt(val, 10)} %`;

    const money = val => `${parseInt(val, 10)} &euro;`;

    const dom = (selector, value, isPercentage) => {
        if (isPercentage) {
            $(selector).html(percentage(value));
        } else {
            $(selector).html(money(value));
        }
    };

    const calculateYELInsurance = () => config.retirement_insurance;

    const calculateBookkeeping = () => config.book_keeping;

    const calculateInsurance = () => config.insurances;

    const calculateEmploymentExpenses = salary => salary * 12 * (1 + config.employment_expenses);

    const updateBillingAndRevenue = annualRevenue => {
        const VAT = annualRevenue * config.vat;
        const billing = annualRevenue * (1 + config.vat);
        dom('.annual-revenue', annualRevenue);
        dom('.annual-vat', VAT);
        dom('.annual-billing', billing);
    };

    const updateStaticExpenses = () => {
        dom('.retirement-insurance', calculateYELInsurance());
        dom('.bookkeeping', calculateBookkeeping());
        dom('.insurances', calculateInsurance());
    };

    const updateEmploymentExpenses = salary => {
        dom('.employment-expenses', calculateEmploymentExpenses(salary));
    };

    const updateTaxAndProfit = (billing, salary) => {
        const YELInsurance = calculateYELInsurance();
        const bookkeeping = calculateBookkeeping();
        const insurance = calculateInsurance();
        const expenses = calculateEmploymentExpenses(salary);
        const profitBeforeTaxes = billing - YELInsurance - bookkeeping - insurance - expenses;
        let taxes = profitBeforeTaxes * config.tax;

        if (taxes < 0) {
            taxes = 0;
        }

        const profit = profitBeforeTaxes - taxes;
        dom('.annual-tax', taxes);
        dom('.annual-profit', profit);
    };

    const updateYearlySalaryAndTaxes = salary => {
        const taxes = veroprosentti({ monthly_salary: salary, holiday_bonus: true });
        const netSalary = taxes.salary - taxes.tax;
        dom('.annual-salary', taxes.salary);
        dom('.annual-tax-percentage', taxes.tax_percentage, true);
        dom('.target-monthly-salary', salary);
        dom('.annual-personal-tax', taxes.tax);
        dom('.annual-net-salary', netSalary);
    };

    const update = () => {
        const annualRevenue = $billingRange.val();
        const monthlySalary = $salaryRange.val();

        updateBillingAndRevenue(annualRevenue);
        updateStaticExpenses();
        updateEmploymentExpenses(monthlySalary);
        updateTaxAndProfit(annualRevenue, monthlySalary);
        updateYearlySalaryAndTaxes(monthlySalary);
    };

    const sendAnalytics = url => {
        if (window.ga) {
            const values = { revenue: $billingRange.val(), salary: $salaryRange.val() };
            window.ga('send', 'event', 'Apply Freelancer', 'Click', JSON.stringify(values));
        }
    };

    const init = () => {
        if (!$billingRange.length || !$salaryRange.length) {
            return;
        }

        $billingRange.on('input change', update);
        $salaryRange.on('input change', update);
        update();
    };

    const destroy = () => {
        $billingRange.off('input change', update);
        $salaryRange.off('input change', update);
    };

    return {
        init,
        destroy
    };
};
