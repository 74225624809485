import $ from '../core/Dom';

export default el => {
    const dom = $(el);

    const prefix = dom.data('prefix');

    const randomInt = (min, max) => {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1)) + min; // max & min both included
    };

    const init = () => {
        dom.attr('data-src', `/assets/static/badge${prefix}-${randomInt(1, 9)}.svg`);
        dom.addClass('lazyload');
        dom.attr('hidden', null);
    };

    const destroy = () => {

    };

    return {
        init,
        destroy
    };
};
