import Flickity from 'flickity';
import $ from '../core/Dom';

export default el => {
    let flkty;
    const dom = $(el);
    const next = dom.find('[data-next]');
    const prev = dom.find('[data-prev]');
    const slides = dom.find('[data-slides]');

    const checkStatus = () => {
        if (flkty) {
            prev.attr('disabled', flkty.prevButton.isEnabled ? null : 'disabled');
            next.attr('disabled', flkty.nextButton.isEnabled ? null : 'disabled');
        }
    };

    const enable = () => {
        console.log(flkty, dom.get(0), next.get(0), prev.get(0), slides);
        if (!flkty) {
            flkty = new Flickity(slides.get(0), {
                contain: true,
                dragThreshold: 10,
                cellAlign: 'left',
                cellSelector: '[data-slide]',
                prevNextButtons: true,
                pageDots: false,
                wrapAround: false,
                freeScroll: true,
                groupCells: true,
                freeScrollFriction: 0.045
            });

            flkty.on('select', checkStatus);

            flkty.on('dragStart', () => {
                document.ontouchmove = e => e.preventDefault();
            });

            flkty.on('dragEnd', () => {
                document.ontouchmove = () => true;
            });

            next.on('click', () => {
                flkty.next();
            });

            prev.on('click', () => {
                flkty.previous();
            });
        }
    };

    const disable = () => {
        if (flkty) {
            next.off('click');
            prev.off('click');
            flkty.destroy();
            flkty = null;
        }
    };

    const init = () => {
        enable();
    };

    const destroy = () => {
        disable();
    };

    return {
        init,
        destroy
    };
};
