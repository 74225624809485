import gsap from 'gsap';
import Headroom from 'headroom.js';
import { Quart, Sine, Quint, Circ } from 'gsap/gsap-core';
import $ from '../core/Dom';
import Viewport from '../core/Viewport';
import Dispatch from '../core/Dispatch';
import { HEADER_FREEZE, HEADER_UNFREEZE } from '../lib/events';

export default el => {
    const dom = $(el);
    const html = $('html');
    const body = $('body');
    const menuTriggers = dom.find('[data-menu-toggle]');
    const menu = dom.find('[data-menu]').get(0);
    const header = dom.find('[data-header]').get(0);

    const hamburger = dom.find('[data-hamburger]');
    const hamburgerTop = hamburger.find('i:nth-of-type(1)').get(0);
    const hamburgerMiddle = hamburger.find('i:nth-of-type(2)').get(0);
    const hamburgerBottom = hamburger.find('i:nth-of-type(3)').get(0);

    let menuOpen = false;
    let headroom = null;

    const hamburgerToX = () => {
        const offset = parseInt($(hamburgerTop).css('marginBottom')) + 1;
        gsap.to(hamburgerTop, { duration: 0.15, y: offset, ease: Sine.easeIn });
        gsap.to(hamburgerBottom, {
            duration: 0.15,
            y: offset * -1,
            ease: Sine.easeIn,
            onComplete: () => {
                gsap.set(hamburgerMiddle, { opacity: 0 });
                gsap.to(hamburgerTop, { duration: 0.2, rotationZ: '45deg', transformOrigin: '50% 50%', ease: Quart.easeOut });
                gsap.to(hamburgerBottom, { duration: 0.2, rotationZ: '-45deg', transformOrigin: '50% 50%', ease: Quart.easeOut });
            }
        });
    };

    const xToHamburger = () => {
        gsap.to(hamburgerTop, { duration: 0.1, rotationZ: '0deg', transformOrigin: '50% 50%', ease: Sine.easeIn });
        gsap.to(hamburgerBottom, {
            duration: 0.15,
            rotationZ: '0deg',
            transformOrigin: '50% 50%',
            ease: Sine.easeIn,
            onComplete: () => {
                gsap.set(hamburgerMiddle, { opacity: 1 });
                gsap.to(hamburgerTop, { duration: 0.15, y: 0, ease: Quart.easeOut, clearProps: 'all' });
                gsap.to(hamburgerBottom, { duration: 0.15, y: 0, ease: Quart.easeOut, clearProps: 'all' });
            }
        });
    };

    const onBodyKeyUp = e => {
        const key = e.key || e.keyCode || e.which || null;
        if (['Escape', 27].indexOf(key) > -1) {
            toggleMenu();
        }
    };

    const openMenu = () => {
        html.addClass('js-menu-open');
        body.on('keyup', onBodyKeyUp);
        gsap.killTweensOf([menu]);
        gsap.to(menu, { duration: 0.5, autoAlpha: 1, ease: Quint.easeInOut });
        headroom.freeze();
        hamburgerToX();
    };

    const closeMenu = () => {
        html.removeClass('js-menu-open');
        body.off('keyup', onBodyKeyUp);
        gsap.killTweensOf([menu]);
        gsap.to(menu, { delay: 0.15, duration: 0.35, autoAlpha: 0, ease: Sine.easeInOut });
        headroom.unfreeze();
        xToHamburger();
    };

    const toggleMenu = () => {
        if (menuOpen) {
            closeMenu();
        } else {
            openMenu();
        }
        menuOpen = !menuOpen;
    };

    const initHeadroom = () => {
        if (!headroom) {
            headroom = new Headroom(header, {
                tolerance: 5
            });
            headroom.init();
            headroom.pin();
            $(header).on('focusin', () => {
                headroom.pin();
            });
        }
    };

    const destroyHeadroom = () => {
        if (headroom) {
            headroom.destroy();
            headroom = null;
            $(header).off('focuswithin');
        }
    };

    const onResize = () => {
        const diff = document.body.scrollHeight - window.innerHeight;

        if (!headroom && diff >= 10) {
            initHeadroom();
        } else if (headroom && diff < 10) {
            destroyHeadroom();
        }
    };

    const onFreeze = () => {
        if (headroom) {
            headroom.unpin();
            headroom.freeze();
        }
    };

    const onUnFreeze = () => {
        if (headroom) {
            headroom.unfreeze();
        }
    };

    const init = () => {
        menuTriggers.on('click', e => {
            e.preventDefault();
            toggleMenu();
        });
        Dispatch.on(HEADER_FREEZE, onFreeze);
        Dispatch.on(HEADER_UNFREEZE, onUnFreeze);
        Viewport.on('resize', onResize);
        onResize();
    };

    const destroy = () => {
        menuTriggers.off('click');
        Dispatch.off(HEADER_FREEZE, onFreeze);
        Dispatch.off(HEADER_UNFREEZE, onUnFreeze);
        Viewport.off('resize', onResize);
    };

    return {
        init,
        destroy
    };
};
