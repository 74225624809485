import gsap from 'gsap';
import serialize from 'form-serialize';
import agent from '../core/request';
import $ from '../core/Dom';
import Dispatch from '../core/Dispatch';
import { HEADER_FREEZE, HEADER_UNFREEZE } from '../lib/events';

export default (el, props) => {
    const dom = $(el);
    const form = el;
    const addLinkButton = dom.find('[data-add-link]');
    const submitButton = dom.find('[data-submit]');

    const dialog = $(el.parentElement).find('[data-dialog]');
    const dialogEmoji = dialog.find('[data-dialog-emoji]');
    const dialogHeading = dialog.find('[data-dialog-heading]');
    const dialogText = dialog.find('[data-dialog-text]');
    const dialogClose = dialog.find('[data-dialog-close]');

    let index = 1;
    let lastFocus = null;

    const addLink = () => {
        index += 1;
        const field = $(addLinkButton.previous().get(0).cloneNode(true));
        const input = field.find('input');
        const label = field.find('label');
        const errorMessage = field.find('.error-message');

        const id = `application-links${index}`;

        input.attr('id', id).attr('aria-invalid', null).attr('aria-describedby', `${id}_error`).val('');
        label.attr('for', id);
        errorMessage.attr('id', `${id}_error`);

        addLinkButton.parent().get(0).insertBefore(field.get(0), addLinkButton.get(0));
        addInvalidListeners();
    };

    const dialogKeyUp = e => {
        const key = e.key || e.keyCode || e.which || null;
        if (['Escape', 27].indexOf(key) > -1) {
            closeDialog();
        }
    };

    const closeDialog = () => {
        dialog.attr('hidden', '');
        dialogClose.off('click', closeDialog);
        dialogClose.off('focusout');
        $('body').off('keyup', dialogKeyUp);
        lastFocus.focus();
    };

    const openDialog = response => {
        lastFocus = document.activeElement;
        dialogEmoji.text(response.emoji);
        dialogHeading.text(response.heading);
        dialogText.html(response.text);
        dialog.attr('hidden', null);
        dialogClose.on('click', closeDialog);
        dialogHeading.focus();
        dialogClose.on('focusout', () => {
            dialogClose.focus();
        });
        $('body').on('keyup', dialogKeyUp);
    };

    const onReset = () => {
        dom.find('[data-file-output]').html('');
        submitButton.attr('disabled', null);
        addLinkButton.parent().find('.textfield').slice(1).remove();
        Array.from(form.elements).forEach(field => {
            field.removeAttribute('aria-invalid', 'true');
            field.removeEventListener('input', removeError);
        });
        addInvalidListeners();
    };

    const submitForm = () => {
        const params = serialize(el, { hash: true });
        const attachment = $(el).find('input[type="file"]').get(0).files[0] || null;
        submitButton.attr('disabled', '');
        agent
            .post('/')
            .field(window.csrfTokenName, window.csrfTokenValue)
            .field('action', params.action)
            .field('data', JSON.stringify(params))
            .attach('attachment', attachment)
            .end((error, res) => {
                const response = JSON.parse(res.text);
                console.log(response);
                if (error || !response.success) {
                    openDialog(props.error);
                } else {
                    openDialog(props.success);
                    form.reset();
                }
            });
    };

    const onSubmit = e => {
        e.preventDefault();
        if (!form.checkValidity()) {
            Dispatch.emit(HEADER_FREEZE);
            const firstInvalidField = dom.find('[aria-invalid="true"]').first();
            const offset = firstInvalidField.offset().top - 20;
            gsap.to(window, { duration: 1, scrollTo: { y: offset, autoKill: false }, ease: 'power2.inOut', onComplete: () => {
                firstInvalidField.get(0).focus();
                Dispatch.emit(HEADER_UNFREEZE);
            } });
            return;
        }
        submitForm();
    };

    const removeError = e => {
        e.target.removeAttribute('aria-invalid');
        e.target.removeEventListener('input', removeError);
    };

    const onInvalid = e => {
        const field = e.target;
        const errorDialog = document.querySelector(`#${field.getAttribute('aria-describedby')} span`);
        if (errorDialog && field.validationMessage) {
            errorDialog.innerHTML = field.validationMessage;
        }
        field.setAttribute('aria-invalid', 'true');
        field.addEventListener('input', removeError);
    };

    const removeInvalidListeners = () => {
        Array.from(form.elements).forEach(field => {
            field.removeEventListener('invalid', onInvalid);
        });
    };

    const addInvalidListeners = () => {
        removeInvalidListeners();
        Array.from(form.elements).forEach(field => {
            field.addEventListener('invalid', onInvalid);
        });
    };

    const init = () => {
        addLinkButton.on('click', addLink);
        submitButton.on('click', onSubmit);
        dom.on('reset', onReset);
        addInvalidListeners();
    };

    const destroy = () => {
        addLinkButton.off('click', addLink);
        submitButton.off('click', onSubmit);
        dom.off('reset', onReset);
    };

    return {
        init,
        destroy
    };
};
