import $ from '../core/Dom';

export default el => {
    const dom = $(el);
    const button = dom.find('[aria-expanded]');

    const toggle = () => {
        const expanded = button.attr('aria-expanded') === 'true';
        if (expanded) {
            button.attr('aria-expanded', 'false');
            $('body').off('keyup', onKeyUp);
            $('body').off('click', onClick);
        } else {
            button.attr('aria-expanded', 'true');
            $('body').on('keyup', onKeyUp);
            $('body').on('click', onClick);
        }
    };

    const onClick = e => {
        if (!el.contains(e.target)) {
            toggle();
        }
    };

    const onKeyUp = e => {
        const key = e.key || e.keyCode || e.which || null;
        if (['Escape', 27].indexOf(key) > -1) {
            toggle();
            button.get(0).focus();
        }
    };

    const init = () => {
        button.on('click', toggle);
    };

    const destroy = () => {
        button.off('click', toggle);
    };

    return {
        init,
        destroy
    };
};
